<template>
  <KPage>
    <!--  -->
    <FilterBar />

    <!--  -->
    <v-card outlined class="overflow-hidden">
      <v-row class="ma-0">
        <v-col cols="12" xs="12" class="pa-0">
          <v-list class="py-0 bordered">
            <v-list-item
              v-for="(item, key) in groups"
              :key="key"
              @click.stop="() => edit(item)"
            >
              <v-list-item-avatar>
                <v-icon>mdi-google-circles-communities</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
                <v-list-item-subtitle>
                  <KChip :blue="item.is_active" :gray="!item.is_active">
                    {{ item.is_active ? "Ativo" : "Inativo" }}
                  </KChip>

                  <KChip :color="item.module && item.module.color">
                    {{ item.module && item.module.label }}
                  </KChip>
                  {{ item.description }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon @click.stop.prevent="() => remove(item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <!-- </v-card-text> -->
          </v-list>
        </v-col>
      </v-row>
    </v-card>

    <!--  -->
    <div style="height: 48px"></div>

    <!-- Caixa de Diálogo -->
    <DialogSave ref="dialog" />
    <DialogDelete ref="delete_dialog" />

    <!-- Botão FAB -->
    <FAB :visible="!loading" @click="add"></FAB>
    <!--  -->
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import KForm from "@/components/KForm";
import KAvatar from "@/components/KAvatar";
import FAB from "@/components/FAB";
import KChip from "@/components/KChip";

import DialogSave from "./DialogSave";
import DialogDelete from "./DialogDelete";
import FilterBar from "./FilterBar";

export default {
  components: {
    KPage,
    KForm,
    KChip,
    KAvatar,
    FAB,
    DialogSave,
    FilterBar,
    DialogDelete
  },

  data() {
    return {
      //
      loading: false,

      //
      dialog: {
        visible: true
      }
    };
  },

  computed: {
    /**
     *
     */
    groups() {
      return this.$store.getters["groups/full"];
    }
  },

  //
  methods: {
    /**
     * Abre o dialog como cadastro
     */
    add() {
      this.$refs["dialog"].openToAdd();
    },

    /**
     * Abre o dialog como edição
     */
    edit(data) {
      this.$refs["dialog"].openToEdit(data);
    },

    /**
     * Abre o dialog de exclusão
     */
    remove(data) {
      this.$refs["delete_dialog"].openToRemove(data);
    },

    /**
     * Carrega os partidos caso ainda não estejam
     */
    async load() {
      try {
        this.loading = true;
        this.$loading.show();
        await this.$store.dispatch("groups/all");
        this.loading = false;
        this.$loading.hide();
      } catch (error) {
        this.loading = false;
        this.$loading.hide();
      }
    }
  },

  mounted() {
    this.load();
  }
};
</script>

