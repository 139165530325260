<!--
  Card que mostra o upload. Vou informar o tipo para mostrar o ícone ou a miniatura

  :file="" // Object file do model de  Uploads da API
  :width="120"
  :height="120"

  show-remove // Mostra o botão de remover
  show-open   // Mostra o botão de abrirem outra aba

  @click:remove="()=>{}" //Ao clicar em remover
  >

  Mostra o ícone ao meio. uma mensagem embaixo dele e as ações embaixo de tudo

-->
<template>
  <v-card
    :width="width"
    :height="height"
    outlined
    class="uc-card d-flex justify-end align-center flex-column"
    color="rgba(0,0,0,.4)"
    @click.stop.prevent="onCardClick(file)"
  >
    <v-img :src="file.url" width="100%" height="100%" v-if="isImage"></v-img>
    <div class="uc-content" :class="{ 'is-image': isImage }">
      <!-- Ícone -->
      <div class="uc-icon" v-if="local_icon">
        <v-icon color="neutral" size="36">{{ local_icon }}</v-icon>
      </div>
      <!-- Nome do arquivo -->

      <!-- Rodapé -->
      <v-card-actions v-if="true" class="pb-0 uc-footer">
        <div class="uc-name text-truncate white--text">{{ file.name }}</div>
        <!-- <v-row class="ma-0"> -->
        <v-btn
          color="white"
          small
          icon
          @click.stop="onRemoveClick"
          v-if="showDelete"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn
          color="white"
          small
          icon
          @click.stop="onOpenInNewClick(file.url)"
          v-if="showOpen && file.url"
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <div class="caption uc-size white--text">{{ local_size }}</div>
        <!-- </v-row> -->
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { filesize } from "@/plugins/utils";
export default {
  name: "upload-card",

  props: {
    /**
     * Object de arquivo contendo todos os dados padrão como name, original_name,
     * url, mime, site etc.
     */
    file: { type: Object, default: () => ({}) },

    /**
     * Mostra o botão de delete?
     */
    showDelete: { type: Boolean, default: true },

    /**
     * Mostra o botão de abrir em nova aba?
     */
    showOpen: { type: Boolean, default: false },

    /**
     * Tamanho do card
     */
    width: [Number, String],
    height: [Number, String]
  },
  data: () => ({}),

  computed: {
    /**
     * É imagem
     */
    isImage() {
      console.log("isImage", this.file.mime, this.local_type);
      if (this.file) {
        return this.local_type.type === "image";
      }
      return false;
    },

    /**
     * Retorna qual os dados de acordo com o tipo
     * Esse array precisa estar igual em UploadView
     */
    local_type() {
      const mimeTypes = [
        {
          type: "audio",
          extension: "mp3",
          mime: [
            "audio/mp3",
            "audio/mpeg",
            "audio/mpeg3",
            "audio/x-mpeg-3",
            "video/mpeg",
            "video/x-mpeg"
          ],
          icon: "mdi-music"
        },
        {
          type: "document",
          extension: "pdf",
          mime: ["application/pdf"],
          icon: "mdi-file-pdf-box"
        },
        {
          type: "image",
          extension: ["jpeg", "jpg"],
          mime: ["image/jpeg"]
        },
        {
          type: "image",
          extension: ["png"],
          mime: ["image/png"]
        },
        {
          type: "image",
          extension: ["webp"],
          mime: ["image/webp"]
        }
      ];

      return (
        mimeTypes.find(item => {
          const isMime = item.mime.includes(this.file.mime);
          // console.log("find", this.file.mime, item.mime, isMime);

          return isMime;
          return item.type === this.file.mime;
        }) || {}
      );
    },

    /**
     * Devolve o tamanho formatado
     */
    local_size() {
      if (this.file) {
        return filesize(this.file.size);
      }
      return "0Kb";
    },
    local_icon() {
      if (this.file) {
        return this.local_type.icon;
      }
      return "mdi-file";
    }
  },
  methods: {
    onCardClick() {
      this.$emit("click:file", this.file);
    },
    onRemoveClick() {
      this.$emit("click:remove", this.file);
    },
    onOpenInNewClick(url) {
      window.open(url, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.uc-message > p {
  border-radius: 5px;
}

.uc-card {
  height: 100%;
}

.uc-card > .uc-content {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  // min-height: 150px;
  width: 100%;
  // height: 100%;
}

.uc-card > .uc-content.is-image {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(0, 0, 0, 0.6) 100%
  );
  position: absolute;
}

.uc-footer {
  width: 100%;
}

.uc-name {
  width: 100%;
  text-align: center;
}

.uc-size {
  white-space: nowrap;
}
</style>
