<template>
  <v-list-item @click.stop="$emit('click:file', file)">
    <v-list-item-avatar>
      <v-icon v-if="file.file.mime === 'application/pdf'"
        >mdi-file-pdf-box</v-icon
      >
      <v-img v-else cover :src="file.file.url"></v-img>
    </v-list-item-avatar>

    <!-- Conteúdo -->
    <v-list-item-content>
      <v-list-item-title v-if="file.title">
        {{ file.title }}
      </v-list-item-title>

      <v-list-item-subtitle v-else class="font-italic"
        >[Clique para adicionar uma descrição]</v-list-item-subtitle
      >

      <v-list-item-subtitle>
        <visibility-chip :visibility_id="file.visibility_id"></visibility-chip>
        <KChip>{{ file.file.mime }}</KChip>
        <KChip>{{ file.file.size | filesize }}</KChip>
      </v-list-item-subtitle>
    </v-list-item-content>

    <!--  -->
    <v-list-item-action>
      <v-row class="ma-0">
        <v-btn icon :href="file.file.url" target="_blank" @click.stop>
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>

        <v-btn icon @click.stop="$emit('click:file', file)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn icon @click.stop="$emit('click:remove', file)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-row>
    </v-list-item-action>
    <!-- {{ file }} -->
  </v-list-item>
</template>

<script>
import KChip from "@/components/KChip";
import VisibilityChip from "./VisibilityChip";

export default {
  name: "list-item-file",

  props: {
    file: { type: Object, default: () => ({}) }
  },

  components: {
    KChip,
    VisibilityChip
  },

  computed: {
    fileType() {
      if (this.file.file.mime === "application/pdf") {
        return {
          icon: "mdi-file-pdf-box",
          image: false
        };
      }

      // imagem
      else if (this.file.file.mime.includes("image/")) {
        return {
          image: true,
          icon: null
        };
      }

      // é imagem
      return {
        icon: "mdi-file-question-outline",
        image: false
      };
    }
  },

  methods: {}
};
</script>

<style>
</style>
