<template>
  <KChip
    :red="options.slug === 'restrict'"
    :yellow="options.slug === 'private'"
    :green="options.slug === 'public'"
    v-text="options.label"
  />
</template>

<script>
import KChip from "@/components/KChip";

export default {
  name: "visiblity-chip",

  components: {
    KChip
  },

  props: {
    visibility_id: [Number, String]
  },

  computed: {
    options() {
      switch (String(this.visibility_id)) {
        case "1":
          return {
            slug: "restrict",
            label: "Restrito"
          };
        case "2":
          return {
            slug: "private",
            label: "Privado"
          };
        default:
        case "3":
          return {
            slug: "public",
            label: "Público"
          };
      }
    }
  }
};
</script>

<style>
</style>
