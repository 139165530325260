<template>
  <KDialog
    :loading="loading"
    :title="title"
    :visible="visible"
    showClose
    :actions="actions"
    @click:add="save"
    @click:edit="save"
    @click:close="close"
  >
    <k-form ref="form">
      <v-row class="ma-0">
        <!-- Nome do Grupo -->
        <v-col cols="12">
          <v-text-field
            dense
            outlined
            :rules="[$validation.isRequired]"
            label="Nome do Grupo"
            :error-messages="errors.name"
            v-model="data.name"
            persistent-hint
            required
          ></v-text-field>
        </v-col>

        <!-- Descrição do Grupo -->
        <v-col cols="12">
          <v-text-field
            dense
            outlined
            :rules="[$validation.isRequired]"
            label="Descrição do Grupo"
            :error-messages="errors.description"
            v-model="data.description"
            persistent-hint
            required
          ></v-text-field>
        </v-col>

        <!-- Módulo -->
        <v-col cols="12">
          <v-select
            :readonly="isEditing"
            dense
            outlined
            :items="modules"
            item-value="slug"
            item-text="label"
            :rules="[$validation.isRequired]"
            label="Módulo de Referência"
            hint="Selecione o módulo ao qual este grupo estará vinculado"
            :error-messages="errors.reference_module"
            v-model="data.reference_module"
            persistent-hint
            required
          ></v-select>
        </v-col>

        <!-- Está ativo? -->
        <v-col cols="12">
          <v-switch
            inset
            dense
            :true-value="1"
            :false-value="0"
            outlined
            :rules="[$validation.isRequired]"
            label="Este grupo está ativo?"
            hint="Grupos inativos não podem mais receber informações e arquivos"
            :error-messages="errors.is_active"
            v-model="data.is_active"
            persistent-hint
            required
          ></v-switch>
        </v-col>
      </v-row>
    </k-form>
  </KDialog>
</template>

<script>
import KDialog from "@/components/KDialog";
import KForm from "@/components/KForm";

export default {
  components: {
    KDialog,
    KForm
  },

  data() {
    return {
      // Define se está visível
      visible: false,
      // Armazena os dados que virão via função para preencher
      data: {},

      isEditing: false,
      title: null,

      loading: false,

      //
      errors: {
        name: null,
        slug: null,
        icon: null
      }
    };
  },

  computed: {
    actions() {
      if (this.isEditing) {
        return [
          {
            eventName: "click:edit",
            label: "Salvar Alterações"
          }
        ];
      }

      return [{ eventName: "click:add", label: "Cadastrar" }];
    },

    modules() {
      return this.$store.getters["groups/modules"];
    }
  },

  methods: {
    /*******************************************************
     *
     *  FUNÇÕES PARA SALVAR E EDITAR OS DADOS NO STORE
     *
     *******************************************************/

    /**
     * Salva os dados no Store
     */
    async save() {
      try {
        //
        this.errors = {};
        // Se as demais validações passaram
        if (this.$refs["form"].validate()) {
          this.loading = true;
          //
          await this.$store.dispatch("groups/save", {
            payload: this.data,
            editing: this.isEditing
          });

          this.loading = false;
          this.$message.success("Rede salva com sucesso");
          this.close();
        } else {
          this.$message.error("Verifique os campos marcados em vermelho");

          this.loading = false;
        }
      } catch (error) {
        this.errors = this.$message.serverError(error);
        this.loading = false;
      }
    },

    /*******************************************************
     *
     *  FUNÇÕES BÁSICAS DE QUALQUER DIALOG
     *
     *******************************************************/

    /**
     * Abre o dialog preparado para cadastrar um novo dado
     */
    openToAdd() {
      // Altera o título
      this.title = "Cadastrar novo grupo";

      // Limpa os dados
      this.clear();

      // Define se é uma edição
      this.isEditing = false;

      // Abre por último, depois que está tudo preenchido
      this.open();
    },

    /**
     * Abre o dialog preparado para alteerar os dados
     */
    openToEdit({ id, name, description, is_active, reference_module }) {
      // Altera o título
      this.title = `Editar "${name}"`;

      // Mescla os dados recebidos para não alterar o que está para trás
      this.data = {
        id,
        name,
        description,
        is_active,
        reference_module
      };

      // Define se é uma edição
      this.isEditing = true;

      // Abre por último, depois que está tudo preenchido
      this.open();
    },

    /**
     * Abre o dialog
     */
    open() {
      this.visible = true;
    },

    /**
     * Fecha o dialog
     */
    close() {
      this.$refs.form.resetValidation();
      this.visible = false;
      this.clear();
    },

    /**
     * Limpa os dados do dialog
     */
    clear() {
      this.data = {};
    }
  }
};
</script>

<style>
</style>
