<template>
  <KDialog
    :title="title"
    :loading="loading"
    :visible="visible"
    :actions="actions"
    @click:close="close"
    @click:save="save"
  >
    <k-form ref="form">
      <v-container>
        <v-row class="my-4">
          <!-- Nome -->
          <v-col cols="12" class="">
            <v-text-field
              outlined
              dense
              label="Descrição do arquivo"
              :error-messages="errors.title"
              :rules="[$validation.isRequired]"
              v-model="form.title"
            ></v-text-field>
          </v-col>

          <!-- Valor -->
          <v-col cols="12" sm="6" class="">
            <v-select
              outlined
              dense
              :rules="[$validation.isRequired]"
              :items="visibilityOptions"
              item-text="label"
              item-value="id"
              label="Visibilidade"
              persistent-hint
              :error-messages="errors.visibility_id"
              v-model="form.visibility_id"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </k-form>
  </KDialog>
</template>

<script>
import KDialog from "@/components/KDialog";
import KForm from "@/components/KForm";

export default {
  components: {
    KDialog,
    KForm
  },

  data() {
    return {
      visible: false,
      form: {},
      title: "Cadastrar Contato",
      loading: false,
      mode: null,
      errors: {}
    };
  },
  watch: {},

  computed: {
    actions() {
      return [{ label: "Salvar alterações", eventName: "click:save" }];
    },
    visibilityOptions() {
      return [
        { id: 1, label: "Restrito" },
        { id: 2, label: "Privado" },
        { id: 3, label: "Público" }
      ];
    }
  },

  methods: {
    /**
     * Salva os dados do contato no banco de dados e devolve o evento
     * para o pai atualizar o store
     */
    async save() {
      try {
        this.errors = {};

        if (this.$refs["form"].validate()) {
          this.loading = true;

          const file = await this.$api.put(
            `/grouping/${this.form.id}`,
            this.form
          );

          this.$emit("file-update", file.data.data);

          this.$message.success("Dados salvos com sucesso");
          this.loading = false;
          this.close();
        }
      } catch (error) {
        this.errors = this.$message.serverError(error);
        this.loading = false;
      }
    },

    /*******************************************************
     *
     *  FUNÇÕES BÁSICAS DE QUALQUER DIALOG
     *
     *******************************************************/

    /**
     * Administrador edita os dados
     */
    openToEdit(data) {
      this.title = `Editar arquivo`;
      this.edit(data);
    },

    /**
     * Abre o dialog preparado para alteerar os dados
     */
    edit({
      id,
      title,
      value = "-",
      grouping_id,
      visibility_id,
      reference_module_id,
      reference_module
    }) {
      this.form = {
        id,
        title,
        value,
        grouping_id,
        visibility_id,
        reference_module_id,
        reference_module
      };

      // Abre por último, depois que está tudo preenchido
      this.open();
    },

    /**
     * Abre o dialog
     */
    open() {
      this.visible = true;
    },

    /**
     * Fecha o dialog
     */
    close() {
      this.visible = false;
      // Atrasando um pouco a limpeza dos dados para
      // não exibir os dados ficando apagados enquanto
      // fecho o dialog
      setTimeout(() => {
        this.clear();
        this.$refs.form.resetValidation();
      }, 100);
    },

    /**
     * Limpa os dados do dialogLimpa os dados do dialog
     */
    clear() {
      this.form = {};
      this.errors = {};
    }
  },

  mounted() {
    // this.$store.dispatch("contact_types/all");
  }
};
</script>

<style>
</style>
