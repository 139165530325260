<template>
  <KPage>
    <!-- Dados do grupo -->
    <v-row class="my-4">
      <v-col cols="12" class="py-1">
        <SectionHeader title="Dados do Grupo" />
      </v-col>
      <v-col cols="12">
        <v-card outlined>
          <v-card-title>{{ data.name }}</v-card-title>
          <v-card-subtitle>
            <KChip :color="module.color">{{ module.label }}</KChip>
            {{ data.description }}
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>

    <!-- Especificações -->
    <v-row class="my-4">
      <v-col cols="12" class="py-1">
        <SectionHeader
          title="Especificações"
          class="pr-4"
          :actions="[
            {
              eventName: 'click:add',
              icon: 'mdi-plus',
              label: 'Cadastrar especificações',
            },
          ]"
          @click:add="onAddValueClick"
        />
      </v-col>

      <!-- Lista de especificações -->
      <v-col cols="12">
        <v-card outlined v-if="data.values && data.values.length > 0">
          <v-list class="py-0 bordered">
            <v-list-item
              v-for="item in data.values"
              :key="item.id"
              @click="onEditValueClick(item)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-list-item-subtitle>
                  <!--  -->

                  <visibility-chip :visibility_id="item.visibility_id" />
                  {{ item.value }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-row class="ma-0">
                  <v-btn icon @click.stop="onEditValueClick(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click.stop="onDeleteValueClick(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>

        <KAlert v-else info>
          Nenhuma especificação cadastrada até o momento
        </KAlert>
      </v-col>
    </v-row>

    <!-- Arquivos -->
    <v-row class="my-4">
      <!-- Botão de upload -->
      <v-col cols="12">
        <Upload
          ref="upload"
          title="Arquivos"
          :accept="['image/jpeg', 'image/png', 'application/pdf']"
          :url-upload="urlUpload"
          :action-delete="(item) => `/constructions/files/${item.id}`"
          :allow-paste-event="true"
          :merge-data="mergeData"
          field-name="file"
          :initial-files="[]"
          :refresh-button="true"
          :delete-button="true"
          :new-page-button="true"
          :thumb-size="120"
          :auto-upload="true"
          :insert-uploaded-file="false"
          :remove-uploaded-file="true"
          :show-uploaded-files="false"
          @click:image="() => {}"
          @upload:success="onUploadSuccess"
        />
      </v-col>

      <!-- Lista de arquivos -->
      <v-col cols="12">
        <SectionHeader title="Arquivos enviados" />
      </v-col>

      <v-col cols="12">
        <v-card outlined v-if="data.files && data.files.length">
          <v-list class="py-0 bordered">
            <ListItemFile
              v-for="item in data.files"
              :key="item.id"
              :file="item"
              @click:file="onOpenFileDialog(item)"
              @click:remove="onRemoveFile(item)"
            />
          </v-list>
        </v-card>

        <KAlert v-else info> Nenhum arquivo enviado até o momento </KAlert>
      </v-col>
    </v-row>

    <DialogFile ref="dialog-file" @file-update="onFileUpdated" />
    <DialogValue
      ref="dialog-value"
      @value-add="onValueAdded"
      @value-edit="onValueUpdated"
    />
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import KChip from "@/components/KChip";
import KAlert from "@/components/KAlert";
import SectionHeader from "@/components/SectionHeader";
//
import Upload from "@/components/Upload";
import UploadFileCard from "@/components/Upload/FileCard";
//
import VisibilityChip from "./VisibilityChip";
import ListItemFile from "./ListItemFile";
//
import DialogFile from "./DialogFile";
import DialogValue from "./DialogValue";

export default {
  name: "groups-view",

  components: {
    KPage,
    KAlert,
    KChip,
    SectionHeader,

    Upload,
    UploadFileCard,
    VisibilityChip,
    //
    ListItemFile,
    DialogFile,
    DialogValue
  },

  // props: {
  //   reference_module: String,
  //   reference_module_id: String
  // },

  data() {
    return {
      data: {},
      mergeData: {}
    };
  },

  computed: {
    // isConstruction() {
    //   return !!this.$route.params.construction_id;
    // },
    // isService() {
    //   return !!this.$route.params.service_id;
    // },
    files() {
      if (this.data?.files) {
        return this.data.files.map(item => ({
          name: item.file.original_name,
          url: item.file.url,
          id: item.id,
          size: item.file.size,
          mime: item.file.mime
        }));
      }

      return [];
    },
    urlUpload() {
      const url = "/grouping/upload?";

      return (
        url +
        new URLSearchParams({
          grouping_id: this.$route.params.group_id,
          // reference_module: this.$route.params.reference_module,
          reference_module_id: this.$route.params.reference_module_id
        })
      );
    },

    module() {
      return (
        this.$store.getters["groups/modules"].find(
          item => item.slug === this.$route.params.reference_module
        ) || {}
      );
    }
  },

  methods: {
    /**
     *
     */
    async loadItems() {
      // console.log("carregar os itens do serviço");
      try {
        this.loading = true;
        const { data } = await this.$api.get(`/grouping`, {
          full: 1, // retorna as informações completas
          reference_module: this.$route.params.reference_module,
          reference_module_id: this.$route.params.reference_module_id,
          group_id: this.$route.params.group_id,
          visibility_level: 1
        });

        // console.log(data);
        this.loading = false;
        this.data = data.data;
        this.$app.title(`Grupo ${data.data.name}`);
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$message.serverError(error);
      }
    },

    //////////////////////////////////
    //
    //  FILES
    //
    //////////////////////////////////

    /**
     *
     *
     */
    onUploadSuccess(file) {
      console.log("onUploadSuccess", file);
      this.data.files.push(file.data);
    },

    /**
     * Ao clicar em abrir a alteração dos dados arquivos
     *
     */
    onOpenFileDialog(file) {
      console.log("onOpenFileDialog", file);
      this.$refs["dialog-file"].openToEdit(file);
    },

    /**
     *
     *
     */
    onFileUpdated(file) {
      console.log("arquivo alterado", file);
      const index = this.data.files.findIndex(item => file.id == item.id);
      console.log({ index });
      this.$set(this.data.files, index, file);
    },

    /**
     * Ao clicar em apagar o arquivo
     *
     */
    async onRemoveFile(file) {
      try {
        await this.$api.delete(`/grouping/${file.id}`);

        const index = this.data.files.findIndex(item => file.id == item.id);
        this.$delete(this.data.files, index);
      } catch (error) {
        this.$message.serverError(error);
      }
    },

    //////////////////////////////////
    //
    //  VALUES
    //
    //////////////////////////////////

    /**
     *
     *
     */
    onAddValueClick() {
      console.log(this.$route.params);
      const payload = {
        reference_module: this.$route.params.reference_module || null,
        reference_module_id: this.$route.params.reference_module_id || null,
        grouping_id: this.$route.params.group_id
      };

      this.$refs["dialog-value"].openToAdd(payload);
    },

    /**
     *
     *
     */
    onEditValueClick(value) {
      this.$refs["dialog-value"].openToEdit(value);
    },

    /**
     *
     *
     */
    onDeleteValueClick(value) {
      try {
        this.$api.delete(`/grouping/${value.id}`);

        const index = this.data.values.findIndex(item => item.id == value.id);
        this.$delete(this.data.values, index);
      } catch (error) {
        this.$message.serverError(error);
      }
    },

    /**
     * Ao receber o evento que o valor adicionado
     *
     */
    onValueAdded(value) {
      this.data.values.push(value);
    },

    /**
     * Ao receber o evento que o valor foi alterado
     *
     */
    onValueUpdated(value) {
      const index = this.data.values.findIndex(item => value.id == item.id);
      this.$set(this.data.values, index, value);
    }
  },

  mounted() {
    this.loadItems();
    this.$store.dispatch("groups/all");
  }
};
</script>

